export const NAV_LINKS = [
  "Home",
  // "How it works",
  "Pricing",
  "Insights",
  "FAQs",
  "Contact-Us"
];

export const HERO_DATA = {
  assurance: "India's 1st",
  heading: "Quick Maintenance App",
  price: "₹199 Room/Month*",
  terms: "*T&C Applied",
  download: "download now on",
};

export const COMPANIES = [
  "Forbes",
  "Inc42",
  "Economic Times",
  "Money Control",
  "YourStory",
];

export const HOW_IT_WORKS = {
  heading: "How it works",
  cards: [
    {
      one: {
        id: 1,
        title: "RAISE A REQUEST",
        description:
          "Select the service you need through the Main10 app, whether it's an urgent fix or scheduled maintenance for your managed property.",
      },
      two: {
        id: 2,
        title: "ASSIGNED IN MINUTES",
        description:
          "A skilled and professional Main10 executive is assigned within 10 minutes. Confirm the OTP when they arrive to start the job.",
      },
      three: {
        id: 3,
        title: "FAST & RELIABLE FIX",
        description:
          "The assigned executive quickly inspects the issue and provides a professional fix. No extra payments, no hassle, just seamless maintenance.",
      },
    },
  ],
};

export const OUR_PLANS = {
  heading: "Our Plans",
};

export const DEMO = {
  heading: "With advance and pre-built features, Just as you",
  headingPart: "need",
  image: "/demo.png",
};

export const TESTIMONIALS = {
  heading: "What our customers are saying about us",
};

export const FAQS = {
  heading: "Frequently Asked Questions",
};

export const BANNER_ONE = {
  heading: "Expert Maintenance, Just a Call Away",
  description: "Connect with our skilled maintenance team for your property maintenance need.",
  buttonText: "Get a Callback",
};

export const BLOG = {
  heading: "Explore our blogs to learn more",
};

export const BANNER_TWO = {
  heading: "Get Fast, Reliable Property Maintenance - Anytime!",
  description:
    "Experience hassle-free property maintenance, Get started by downloading our app today!",
};
