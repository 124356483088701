"use client";

import { BANNER_ONE } from "../../constants/data";
import Image from "next/image";
import { useForm } from "../../context/FormContext";

export default function Banner(props) {
  const { openForm } = useForm();

  return (
    <>
      <div className="section-gap py-8 md:py-10 !bg-primary600 relative overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute right-0 top-0 w-64 h-64 bg-white/10 rounded-full -translate-y-1/2 translate-x-1/2 blur-3xl" />
          <div className="absolute left-0 bottom-0 w-64 h-64 bg-white/10 rounded-full translate-y-1/2 -translate-x-1/2 blur-3xl" />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-6 md:gap-10">
            {/* Content Section */}
            <div className="flex-1 text-left w-full">
              <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-primary mb-3">
                {props.breakTitle ? (
                  <>
                    <span className="hidden md:block">{props.heading}</span>
                    <span className="block md:hidden">
                      Expert Maintenance,
                      <br />
                      Just a Call Away
                    </span>
                  </>
                ) : 
                props.heading
                }
              </h2>
              <p className="text-sm text-primary/90 max-w-xl">
                {props.description}
              </p>
            </div>

            {/* CTA Section */}
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full md:w-auto">
              {props.button ? (
                <button
                  onClick={openForm}
                  className="bg-primary hover:bg-primary/90 transform hover:scale-105
                    transition-all duration-300 rounded-xl px-8 py-3.5 
                    text-primary600 font-semibold text-base w-full sm:w-auto min-w-[180px]
                    shadow-lg hover:shadow-xl active:scale-95"
                >
                  {BANNER_ONE.buttonText}
                </button>
              ) : (
                <div className="flex flex-row items-center gap-3 w-full md:w-auto">
                  <a
                    href={process.env.NEXT_GOOGLE_PLAY_STORE_LINK}
                    target="_blank"
                    rel="noreferrer"
                    className="transform hover:scale-105 transition-all duration-300"
                  >
                    <Image
                      src="/banner-app-store.svg"
                      width={120}
                      height={40}
                      alt="Download on App Store"
                      className="h-[40px] w-auto"
                    />
                  </a>
                  <div className="relative group transform hover:scale-105 transition-all duration-300">
                    <Image
                      src="/banner-play-store.svg"
                      width={120}
                      height={40}
                      alt="Get it on Google Play"
                      className="h-[40px] w-auto opacity-50"
                    />
                    <div className="absolute inset-0 flex items-center justify-center 
                      bg-black/60 opacity-0 group-hover:opacity-100 
                      transition-all duration-300 rounded-lg backdrop-blur-sm"
                    >
                      <span className="text-white font-medium text-sm">
                        Coming Soon
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
